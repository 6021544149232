import React from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import withRouter from "../../../../withRouter";
import { findObjectUseCase } from "../../../../usecases/object";
import TransTypeDistributionPresenter from "./TransTypeDistributionPresenter";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import { Progress } from "nq-component";
import DateRange from "../../../../components/DateRange";
import DateRangeReport from "../../../../components/DateRangeReport";

class TransTypeDistributionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TransTypeDistributionPresenter(
      this,
      findObjectUseCase()
    );
    this.state = {
      objects: [],
      startDate: "",
      endDate: "",
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) => {
      // Remove "ANGELSPIZZA_" from the start and return the rest
      return obj.store_code.replace("ANGELSPIZZA_", "");
    });

    console.log("store", storeCodes);

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleStartDateChange(event) {
    this.setState({ startDate: event.target.value });
  }

  handleEndDateChange(event) {
    this.setState({ endDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  filterTransactions() {
    const { objects, startDate, endDate, selectedStoreCode } = this.state;

    return objects
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.createdAt);
        const start = startDate
          ? new Date(startDate)
          : new Date(-8640000000000000);
        const end = endDate ? new Date(endDate) : new Date();
        end.setHours(23, 59, 59, 999);
        const isDateInRange =
          (!startDate || transactionDate >= start) &&
          (!endDate || transactionDate <= end);
        const isStoreCodeMatch = selectedStoreCode
          ? transaction.store_code === `ANGELSPIZZA_${selectedStoreCode}`
          : true;

        return isDateInRange && isStoreCodeMatch;
      });
  }

  chartData(objects) {
    const filteredTransactions = this.filterTransactions();
    let counts = { D: 0, T: 0, G: 0, P: 0, U: 0, B: 0, U: 0, K: 0, F: 0 };
    filteredTransactions.forEach((item) => {
      if (counts.hasOwnProperty(item.tran_type)) {
        counts[item.tran_type]++;
      }
    });
    return {
      labels: [
        "Dine-in",
        "Take-out",
        "GrabFood",
        "Delivery",
        "Drive-Thru",
        "FoodPanda",
      ],
      datasets: [
        {
          label: "Transaction Types",
          data: [counts.D, counts.T, counts.G, counts.U, counts.K, counts.F],
          backgroundColor: [
            "#1b74bb",
            "#37c6f4",
            "#ffcf39",
            "pink",
            "green",
            "#db3373",
          ],
          borderColor: [
            "#1b74bb",
            "#37c6f4",
            "#ffcf39",
            "pink",
            "green",
            "#db3373",
          ],
          borderWidth: 1,
        },
      ],
    };
  }

  chartOption() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Transaction Type Distribution",
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { selectedStoreCode, startDate, endDate, storess } = this.state;
    const chartData = this.chartData(this.state.objects);

    // Construct CSV data array
    const csvData = chartData.datasets[0].data.map((count, index) => {
      return {
        Brand: "Angels Pizza", // Replace with actual brand logic
        Store: storess || "All Stores",
        DateRange: `${this.state.range.tran_date["$lte"].split("T")[0]} ${
          this.state.range.tran_date["$gte"].split("T")[0]
        }`,
        TransactionType: chartData.labels[index],
        Count: count,
      };
    });

    const csvString = this.convertToCSV(csvData);

    // Trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "TransactionTypeDistribution.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const { startDate, endDate } = this.state;
    const objects = this.state.objects;
    const data = this.chartData(objects);
    const options = this.chartOption();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container9";
    const formatSales = (sales) => {
      return sales.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}> Transaction Type</span>
            <span style={{ color: "black" }}> Distribution</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
            <DateRangeReport
              onChange={this.onChangeDate.bind(this)}
              field="tran_date"
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container9">
            <div className="card pb-4">
              <Pie data={data} options={options} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="d-flex justify-content-between align-items-center print-only-content">
                <h3>Brand: Angels Pizza</h3>
                <h4 className="mx-auto"></h4>
                <h3>Store: {this.state.storess}</h3>
                <h3>
                  Date:{" "}
                  {`${this.state.range?.tran_date["$lte"]?.split("T")[0]} ${
                    this.state.range?.tran_date["$gte"]?.split("T")[0]
                  }`}
                </h3>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Sales</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {data.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>{formatSales(data.datasets[0].data[index])}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TransTypeDistributionPage);
